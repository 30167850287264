import * as React from 'react';
import { useState } from 'react';
import styled from 'styled-components';
import StarIcon from '@icons/star.svg';
import BookmarkModal from '@components/modal/BookmarkModal';
import useMounted from '@hooks/useMounted';

export const BookmarkButtonWrap = styled.div`
  ${({ theme }) => theme.font.body2};
  color: ${({ theme }) => theme.colors.white};
  display: flex;
  align-items: center;
  gap: 2px;
  background: rgba(255, 255, 255, 0.1);
  border: 1px solid ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  outline: none;
  width: fit-content;
  padding: 7px 12px 7px 11px;
  cursor: pointer;

  &:hover,
  &:active {
    background: rgba(255, 255, 255, 0.2);
  }
`;

const BookmarkButton: React.FC = () => {
  const isMounted = useMounted();
  const [isModalOpen, setModalOpen] = useState(false);

  const getBrowserType = () => {
    if (isMounted) {
      const test = (regexp: RegExp) => {
        return regexp.test(navigator.userAgent);
      };

      // 순서 변경 시 테스트 필수
      if (test(/samsungbrowser/i)) {
        return 'Samsung';
      }
      if (test(/chrome|chromium|crios/i)) {
        return ''; // 크롬 팝업 추가 필요
      }
      if (test(/safari/i)) {
        return 'Safari';
      }
      if (test(/trident/i)) {
        return 'IE';
      }
      return '';
    }
    return '';
  };

  const addBookmark = () => {
    const { title } = document;
    const url = document.location.href;

    // @ts-ignore
    if (getBrowserType() === 'IE' && window.external) {
      /* IE Favorite */
      // @ts-ignore
      window.external.AddFavorite(url, title);
    } else {
      /* Other */
      setModalOpen(true);
    }
  };

  if (!isMounted || !getBrowserType()) {
    return null;
  }
  return (
    <>
      <BookmarkButtonWrap onClick={addBookmark}>
        <StarIcon />
        <span>즐겨찾기</span>
      </BookmarkButtonWrap>
      <BookmarkModal
        isOpen={isModalOpen}
        onClose={() => setModalOpen(false)}
        browser={getBrowserType()}
      />
    </>
  );
};

export default BookmarkButton;
