import * as React from 'react';
import { StyledButton } from './styles/button.style';
import { defaultTheme } from '@styles/theme';

interface Props {
  children?: string | JSX.Element | JSX.Element[];
  widthPercent?: number;
  $size?: 'default';
  $style?: keyof (typeof defaultTheme)['buttonColor'];
  disabled?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

const Button: React.FC<Props & React.HTMLAttributes<HTMLButtonElement>> = ({
  children,
  widthPercent,
  $size = 'default',
  $style = 'white',
  disabled = false,
  onClick,
  ...rest
}) => (
  <StyledButton
    widthPercent={widthPercent}
    $size={$size}
    $style={$style}
    disabled={disabled}
    onClick={onClick}
    {...rest}
  >
    {children}
  </StyledButton>
);

export default Button;
