import { create } from 'zustand';
import { createJSONStorage, persist } from 'zustand/middleware';
import { ApplyFormType } from '@features/4cut-ranking/apply/types';

export const initialState: ApplyFormType = {
  seasonId: '',
  photoCardCategory: '',
  photoCardName: '',
  countryNumber: '+82',
  phoneNumber: '',
  originPhotoPath: '',
  originPhoto: undefined,
  resizedOriginPhoto: undefined,
  thumbnailPhotoPath: '',
  thumbnailPhoto: undefined,
  ageAgreement: false,
  termsOfUseAgreement: false,
  privacyPolicyAgreement: false,
  acceptedBy: 'NONE',
};

interface Return {
  data: ApplyFormType;
  updateData: (partialData: Partial<ApplyFormType>) => void;
  resetAndUpdateData: (partialData: Partial<ApplyFormType>) => void;
  resetData: () => void;
}

export const useApplyFormStore = create<Return>((set) => ({
  data: initialState,
  updateData: (partialData: Partial<ApplyFormType>) =>
    set((prev: { data: ApplyFormType }) => ({
      data: {
        ...prev.data,
        ...partialData,
      },
    })),
  resetAndUpdateData: (partialData: Partial<ApplyFormType>) =>
    set({
      data: {
        ...initialState,
        ...partialData,
      },
    }),
  resetData: () =>
    set({
      data: initialState,
    }),
}));

export const useShowNonUserKcpCertStore = create(
  persist<{
    isShowKcpCertPage: boolean;
    setShowKcpCertPage: (value: boolean) => void;
  }>(
    (set) => ({
      isShowKcpCertPage: false,
      setShowKcpCertPage: (value: boolean) =>
        set({
          isShowKcpCertPage: value,
        }),
    }),
    {
      name: 'showNonUserKcpCert',
      storage: createJSONStorage(() => sessionStorage),
    },
  ),
);

export const useNeverShowApplyGuideStore = create(
  persist<{
    neverShowApplyGuide: boolean;
    setNeverShowApplyGuide: () => void;
  }>(
    (set) => ({
      neverShowApplyGuide: false,
      setNeverShowApplyGuide: () =>
        set({
          neverShowApplyGuide: true,
        }),
    }),
    {
      name: 'neverShowApplyGuide',
    },
  ),
);
