import { type GetServerSideProps, NextPage } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { useEffect } from 'react';
import MainContent from '@features/MainContent';
import PushAndSettingIconButtons from '@components/layout/PushAndSettingIconButtons';
import PageLayout from '@components/layout/PageLayout';
import { customTagManager } from '@src/customTagManager';
import useDeviceStore from '@hooks/useDeviceStore';

const Main: NextPage = () => {
  const [deviceInfo] = useDeviceStore();
  const isApp = !!deviceInfo?.deviceToken;

  useEffect(() => {
    if (!deviceInfo?.deviceToken) {
      customTagManager.pageViewRankingWeb();
    }
  }, [isApp]);

  return (
    <PageLayout
      $style="transparent"
      titleAlign="left"
      headerRightButton={<PushAndSettingIconButtons />}
    >
      <MainContent />
    </PageLayout>
  );
};

export const getServerSideProps: GetServerSideProps = async ({
  locale = 'ko',
}) => ({
  props: {
    ...(await serverSideTranslations(locale)),
  },
});

export default Main;
