import * as React from 'react';
import ReactModal from 'react-modal';
import Image from 'next/image';
import {
  ContentWrap,
  getBookmarkModalStyles,
  TitleWrap,
} from '@components/modal/styles/bookmarkModal.style';
import Button from '@components/common/Button';
import safari1 from '@public/images/bookmark/safari-1.png';
import safari2 from '@public/images/bookmark/safari-2.png';
import samsung1 from '@public/images/bookmark/samsung-internet-1.png';
import samsung2 from '@public/images/bookmark/samsung-internet-2.png';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  browser: string;
}

const BookmarkModal: React.FC<Props> = ({ isOpen, onClose, browser }) => {
  const renderSafariContent = () => {
    return (
      <ul>
        <li>
          <Image src={safari1} alt="safari1" />
          <p>
            1. 하단의 <span className="pink">공유하기</span> 버튼을 눌러주세요.
          </p>
        </li>
        <li>
          <Image src={safari2} alt="safari2" />
          <p>
            2. <span className="pink">즐겨찾기에 추가</span> 버튼을 선택해{' '}
            <span className="pink">네컷랭킹</span>을<br />
            추가해주세요!
          </p>
        </li>
      </ul>
    );
  };

  const renderSamsungInternetContent = () => {
    return (
      <ul>
        <li>
          <Image src={samsung1} alt="samsung1" />
          <p>
            1. 상단 메뉴를 누른 뒤{' '}
            <span className="pink">현재 페이지 추가</span>
            <br />
            버튼을 선택해주세요.
          </p>
        </li>
        <li>
          <Image
            src={samsung2}
            alt="samsung2"
            className="static-width"
            width={160}
          />
          <p>
            2. <span className="pink">북마크</span> 버튼을 선택해{' '}
            <span className="pink">네컷랭킹</span>을 추가해주세요!
          </p>
        </li>
      </ul>
    );
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      style={getBookmarkModalStyles()}
      ariaHideApp={false}
    >
      <TitleWrap>⭐ 즐겨찾기 가이드</TitleWrap>
      <ContentWrap>
        {browser === 'Safari' && renderSafariContent()}
        {browser === 'Samsung' && renderSamsungInternetContent()}
      </ContentWrap>
      <Button onClick={onClose} widthPercent={40}>
        확인
      </Button>
    </ReactModal>
  );
};
export default BookmarkModal;
