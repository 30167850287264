import React from 'react';
import styled from 'styled-components';
import useNavigator from '@hooks/useAppRouter';
import BookmarkButton from '@components/common/BookmarkButton';
import useDeviceStore from '@hooks/useDeviceStore';
import useUserInfoStore from '@hooks/useUserInfoStore';
import useMounted from '@hooks/useMounted';
import { useApplyFormStore } from '@src/stores/4cut-ranking/applyStore';
import { customTagManager } from '@src/customTagManager';

const MainPageWrap = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  height: 100%;

  > div.bookmark-button-container {
    margin: 0 20px 8px;
  }

  > div.image-container {
    background-image: url('/images/main-content.webp');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    flex: 1 1 0;
    margin-top: 8px;
    min-height: 400px;
  }

  > div.button-container {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 0 20px 32px;

    > div {
      flex: 1 1 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
`;

const MainButton = styled.button`
  ${({ theme }) => theme.font.body1_md};
  ${({ theme }) => theme.font.hGGGothicssi};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 13px 11px;
  border-radius: 12px;
  outline: none;
  background: rgba(255, 255, 255, 0.1);
  color: ${({ theme }) => theme.colors.white};
  position: relative;
  box-shadow: inset 0 0 0 1px ${({ theme }) => theme.colors.white};
  width: 100%;
  white-space: nowrap;
  overflow: hidden;

  &:hover,
  &:active {
    font-size: 15px;
    line-height: 23px;
    padding: 12px 10px;
    margin: 0 2px;
  }

  @media screen and (max-width: 320px) {
    white-space: pre-wrap;
    word-break: keep-all;
  }
`;

const MainContent: React.FC = () => {
  const router = useNavigator();
  const isMounted = useMounted();

  const [deviceInfo] = useDeviceStore();
  const isApp = !!deviceInfo?.deviceToken;

  const [userInfo] = useUserInfoStore();
  const { resetData } = useApplyFormStore();

  const handleClickPlayGame = () => {
    router.push('/4cut-ranking/game/category');
  };

  const handleClickApply = () => {
    customTagManager.registerCardClicked();
    if (!isApp) {
      customTagManager.registerCardClickedWeb();
    }

    if (userInfo?.userId) {
      router.push('/4cut-ranking/apply/category');
    } else {
      router.push('/login?anonymous4cutRankingApply=true');
    }
    resetData();
  };

  return (
    <MainPageWrap id="main">
      {isMounted && !deviceInfo?.deviceId && (
        <div className="bookmark-button-container">
          <BookmarkButton />
        </div>
      )}
      <div className="image-container" />
      <div className="button-container">
        <div>
          <MainButton onClick={handleClickPlayGame}>네컷랭킹 월드컵</MainButton>
        </div>
        <div>
          <MainButton onClick={handleClickApply}>네컷랭킹 참가하기</MainButton>
        </div>
      </div>
    </MainPageWrap>
  );
};

export default MainContent;
