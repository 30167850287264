import styled from 'styled-components';
import ReactModal from 'react-modal';
import { defaultTheme } from '@styles/theme';

export const getBookmarkModalStyles = (): ReactModal.Styles => ({
  content: {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    border: 'none',
    width: 'calc(100% - 20px)',
    maxWidth: 'calc(480px - 20px)',
    height: 'fit-content',
    backgroundColor: defaultTheme.colors.gray80,
    borderRadius: '20px',
    padding: '32px 16px',
    color: defaultTheme.colors.white,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  overlay: {
    background: defaultTheme.colors.dimmed,
    zIndex: defaultTheme.zIndex.modal,
  },
});

export const TitleWrap = styled.p`
  ${({ theme }) => theme.font.title3};
`;

export const ContentWrap = styled.div`
  ${({ theme }) => theme.font.body3};
  margin: 28px 0;
  position: relative;
  display: flex;
  flex-direction: column;

  > ul {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 25px;

    > li {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 12px;

      > img:not(.static-width) {
        width: 100%;
        height: fit-content;
        object-fit: contain;
      }

      > p {
        white-space: pre-line;
        text-align: center;
        color: ${({ theme }) => theme.colors.gray30};

        > span.pink {
          color: ${({ theme }) => theme.colors.pink20};
        }
      }
    }
  }
`;
